
            @import 'src/styles/mixins.scss';
        
.thank-you-page {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}
.offersList {
    h2 {
        font-weight: 400 !important;
        font-size: 18px !important;
        color: #104e8c;
    }
}
.widgetWrapper {
    & > div {
        @media screen and (max-width: 730px) {
            width: 100%;
        }
    }
}
.selling-point {
    font-size: 11px;
    color: #999;
    padding-top: 10px;
    font-style: italic;
}
.main-banner {
    max-width: 700px;
    margin: 50px auto;
    .form {
        z-index: 5;
        width: 100%;

        padding: 40px 20px;

        background-color: #f3f8fe;

        display: flex;

        flex-direction: column;

        position: relative;

        @include lg {
            padding: 50px 85px;
        }
        .step:nth-of-type(1) {
            .form-field {
                > label {
                    text-align: center;
                    max-width: 662px;
                    margin: 10px auto 0 auto;
                    margin-bottom: 10px;

                    font-weight: bold;
                    font-size: 16px;
                    @include lg {
                        font-size: 18px !important;
                        line-height: 26px !important;
                    }
                }
            }
        }

        .step-title {
            text-align: center;
            font-size: 18px !important;
            max-width: 662px;
            margin: 0 auto;

            @include lg {
                font-size: 25px !important;
            }
            font-weight: 700 !important;
        }
        .label-as-step-title {
            text-align: center;
            font-size: 18px !important;
            max-width: 662px;
            margin: 0 auto;

            margin-bottom: 10px;
            @include lg {
                font-size: 25px !important;
            }
            font-weight: 700 !important;
        }
        .step-description {
            text-align: center;
            max-width: 662px;
            margin: 20px auto 0 auto !important;

            font-size: 16px;
            @include lg {
                font-size: 18px !important;
                line-height: 26px !important;
            }
        }

        .form-button {
            max-width: 520px;

            margin: 0 auto !important;
            margin-top: 5px !important;
            border-radius: 8px !important;
            background-color: #4992e9 !important;
            transition: all 0.2s ease-in-out;
            color: #fff !important;
            @apply shadow-lg;

            svg {
                path {
                    fill: #fff;
                }
            }

            &:hover {
                @apply shadow;
            }
        }

        .form-field-label {
            font-size: 16px;
        }
        .form-field {
            max-width: 700px;
            margin: 40px auto;
        }
        .radio-field {
            display: block;

            width: 100%;
            max-width: 520px;
            margin: 10px auto 0;
            & > div {
                width: 100%;
                margin-bottom: 15px;
                display: flex;

                input {
                    &:checked + label {
                        border: 1px solid #4992e9;
                        background-color: #eff5ff;
                    }
                }
                label {
                    border-radius: 8px;

                    border: 1px solid #4992e9;
                    background-color: #fff;

                    img {
                        width: 100%;
                        height: 40px;
                    }
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    gap: 20px;
                    flex-direction: row;
                    text-transform: capitalize;
                    &:hover {
                        border: 1px solid #4992e9;
                        background-color: #eff5ff;
                    }
                    @include md {
                        min-height: 54px;
                    }
                    svg {
                        margin-bottom: 10px;
                        width: 30px;
                        height: auto;
                    }
                    span {
                        font-size: 16px;
                    }
                }
            }
        }
        .checkbox-field {
            & > div {
                width: 100%;
                max-width: 520px;
                background-color: #fff;
                margin-bottom: 15px;
                display: flex;
                flex-direction: column;
                input {
                    &:checked + label {
                        font-weight: 500;
                        background-color: #eff5ff;
                    }
                }
                label {
                    border-radius: 3px;
                    border: 1px solid #4992e9;
                    color: black;
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    text-transform: capitalize;
                    @include md {
                        min-height: 54px;
                    }
                }
                label:hover {
                    background-color: #eff5ff;
                }
            }
        }
        .select-field {
            background-color: #fff;
        }
        .date-filed {
            select {
                background-color: #fff;
            }
        }
        .field-note {
            margin-left: 7px;
        }
        .field-error-message {
            left: 7px;
        }
    }
}

.styleForFirstLabelWithoutTitle {
    .form {
        .step:nth-of-type(1) {
            .form-field {
                > label {
                    text-align: center;
                    font-size: 18px !important;
                    max-width: 662px;
                    margin: 0 auto;

                    margin-bottom: 10px;
                    @include lg {
                        font-size: 25px !important;
                        line-height: 40px !important;
                    }
                    font-weight: 700 !important;
                }
            }
        }
    }
}
