
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.lender-card {
    border: 1px solid #eee;
    border-radius: 4px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
    margin-top: 10px;
    padding: 10px 15px;
    background: #fff;

    display: grid;
    grid-gap: 0 20px;
    grid-template-areas:
        "metadata metadata metadata"
        "apr      payment  rate    "
        "fees     fees     fees    "
        "cta      cta      cta     ";
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: repeat(4, max-content);
    position: relative;
    transition: box-shadow 0.2s ease-in-out;

    @include md {
        grid-template-areas:
            "metadata apr  payment rate"
            "metadata fees fees    fees"
            "metadata cta  cta     cta ";
        grid-template-columns: 140px minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        grid-template-rows: repeat(3, max-content);
    }

    @include lg {
        grid-template-areas:
            "metadata apr       payment rate fees cta"
            "metadata loan_type payment rate fees cta";
        grid-template-columns: minmax(140px, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(
                0,
                1fr
            );
    }

    &:hover {
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.09); 
    }

    .lender-metadata {
        display: grid;
        grid-area: metadata;
        grid-gap: 0 20px;
        grid-template-areas:
            "logo loan_type"
            "logo ids      ";
        grid-template-columns: 100px 1fr;
        grid-template-rows: max-content 1fr;
        margin-bottom: 10px;
        @include md {
            display: block;
            margin: 0;
        }
        .lender-logo {
            width: 120px;
            grid-area: logo;
            @include md {
                padding-right: 20px;
                width: 140px;
            }
            a {
                img {
                    aspect-ratio: 143/48;
                    height: auto;
                    max-width: 100%;
                    width: auto;
                    vertical-align: middle;
                }
            }
        }
        .id-numbers {
            @include md {
                text-align: left;
            }
            color: #868686;
            font-size: 10px;
            grid-area: ids;
            margin-top: 3px;
            text-align: right;
            .nmls-id {
                font-size: 10px;
                color: #868686;
            }
        }
        .loan-type {
            font-size: 10px;
            display: block;
            font-size: 14px;
            grid-area: loan_type;
            text-align: right;

            @include md {
                margin-top: 10px;
                text-align: left;
                display: block;
                color: black;
                grid-area: unset;
            }
            @include lg {
                display: none;
            }
        }
    }
    .apr {
        grid-area: apr;
        .loan-type {
            display: none;
            @include lg {
                display: block;
                font-size: 12px;
                margin-top: 1px;
                color: #696969;
            }
        }
        .data-value {
            font-weight: bold;
        }
    }

    .payment {
        grid-area: payment;
    }

    .rate {
        grid-area: rate;
    }

    > .loan-type {
        color: black;
        font-size: 14px;
        display: none;
        grid-area: loan_type;
        text-align: right;
    }

    .fees-and-points {
        grid-area: fees;
        line-height: 1.2;
        margin-top: 10px;

        @include lg {
            margin-top: 0px;
        }

        .data-label {
            display: inline-block;
            font-size: 12px;
            span {
                margin-left: 0;
                padding: 0;
                font-size: 12px;
                text-transform: initial;
                background: none;
                @include lg {
                    text-transform: uppercase;
                    background: unset;
                    padding: 2px 5px;
                    margin-left: -5px;
                    color: #727272;
                    background: #f8f8f8;
                }
            }
            &::after {
                content: ": ";
                margin-right: 3px;
                @include lg {
                    display: none;
                }
            }
            @include lg {
                display: block;
            }
        }
        .data-value {
            display: inline-block;
            font-size: 14px;
            font-weight: bold;

            @include lg {
                display: block;
                font-size: 16px;
                margin-top: 5px;
            }

            .fee-breakdown-and-button {
                display: inline-block;
                font-weight: normal;
                margin-left: 5px;
                margin-right: 5px;
                button {
                    background: none;
                    border: 0;
                    cursor: pointer;
                    height: 18px;
                    margin: 0;
                    padding: 0;
                    position: relative;
                    top: -1px;
                    vertical-align: bottom;
                    width: 18px;
                }
            }
        }
        .secondary-data-value {
            color: rgb(102, 102, 102);
            font-size: 12px;
            @include md {
                display: inline-block;
                margin-left: 10px;
            }
            @include md {
                margin-left: 0px;
            }
        }
    }

    .cta {
        grid-area: cta;
        margin-top: 10px;
        .button {
            display: block;
            width: 100%;
            text-decoration: none;

            appearance: none;
            background: black;
            border: 1px solid black;
            border-radius: 5px;
            color: white;
            cursor: pointer;
            display: block;
            font-family: inherit;
            font-size: 16px;
            font-weight: bold;
            line-height: inherit;
            margin: 0;
            padding: 8px 10px;
            text-align: center;
        }
    }

    .data-label {
        font-size: 11px;
        span {
            color: #727272;
            background: #f8f8f8;
            border-radius: 4px;
            display: inline-block;
            line-height: 1.25;
            margin-left: -5px;
            padding: 2px 5px;
            text-transform: uppercase;
        }
    }

    .data-value {
        line-height: 1.25;
        font-size: 18px;
        color: black;
        @include md {
            font-size: 24px;
        }
        @include lg {
            font-size: 28px;
        }
        small {
            font-size: 11px;
            font-weight: normal;
            display: inline;
            color: #767676;
        }
    }

    .fee-breakdown {
        width: 250px;
        position: absolute;
        right: -125px;

        @include lg {
            width: 280px;
            left: auto;
            right: -30px;
        }

        .header {
            background: #f8f8f8;
            border-bottom: 1px solid #c3c3c3;
            border-radius: 4px 4px 0 0;
            font-size: 16px;
            font-weight: bold;
            margin: -10px -10px 0 -10px;
            padding: 11px 10px 10px 10px;
            text-align: left;
            caption-side: inherit;
            color: black;
        }

        > div > p {
            display: flex;
            justify-content: space-between;
            margin: 10px 0px;
            &:last-child {
                font-weight: bold;
                border-top:  1px solid #c3c3c3;
                padding-top: 10px;
            }
        }


        background: white;
        border: 1px solid #c3c3c3;
        border-radius: 4px;
        box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.1);
        margin-top: 4px;
        padding: 10px;
        z-index: 1;

        .about-points {
            color: rgb(117, 117, 117);
            font-size: 12px;
            margin-top: 20px;
            text-align: left;
        }
    }
}

@keyframes blink {
    0% {
        transform: skewX(-45deg) translateX(-130%);
    }

    100% {
        transform: skewX(-45deg) translateX(20rem);
    }
}
