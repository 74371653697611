$insurance-super-saver-blue-aster: #266199;
$insurance-super-saver-assault: #184070;
$insurance-super-saver-lucea: #79b4e2;
$insurance-super-saver-distant-horizon: #f4f9fa;

:export {
    blueAster: $insurance-super-saver-blue-aster;
    assault: $insurance-super-saver-assault;
    lucea: $insurance-super-saver-lucea;
    distantHorizon: $insurance-super-saver-distant-horizon;
}
